<template>
  <editor
    v-bind="$attrs"
    v-on="$listeners"
    :mentions="macroIntellisense"
    @initialized="onInitiailized"
    @customize-modules="customizeQuillModules"
    :name="nameProp"
    ref="editor"
  />
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MacroModule, { macroStore } from "@/modules/Quill/MacroIntellisense.js";
import Editor from "./Editor.vue";
import Macro from "@/modules/Quill/Macro";
import Quill from "devextreme-quill";
import { getDefaultStyles } from "@/modules/getDefaultStylesByField";

Quill.register({ "modules/macro": Macro });

export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true
    },
    isExpanded: {
      type: Boolean,
      default: false
    },
    autoFocus: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Editor
  },
  data: () => ({
    component: {},
    _editor: null
  }),
  mounted() {
    if (this.isExpanded) {
      this.expand();
    }
    if (this.autoFocus) {
      setTimeout(() => {
        this.focus();
      }, 400);
    }
  },
  methods: {
    onInitiailized({ component }) {
      component.register({ "modules/mentions": MacroModule }, true);
    },
    focus() {
      if (this.$refs?.editor?.focus) {
        this.$refs.editor.focus();
      }
    },
    expand() {
      if (this.$refs?.editor?.expand) {
        this.$refs.editor.expand();
      }
    },
    collapse() {
      if (this.$refs?.editor) {
        this.$refs.editor.collapse();
      }
    },
    customizeQuillModules(config) {
      config.macro = {
        name: this.name,
        styles: this.styles,
        initialized: true,
        generalOnly: true
      };
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      applicationSettings: state => state.applicationSettings,
      currentLab: state => state.currentLab,
      MacroSearchWithPeriod: state => state.labSettings.MacroSearchWithPeriod,
      currentSpecimen: state => state.accessionStore.currentSpecimen
    }),
    ...mapGetters(["permissions"]),
    webSpellCheckInstance() {
      if (this.$refs.editor) {
        return this.$refs.editor.webSpellCheckInstance;
      }
      return null;
    },
    nameProp() {
      return this.name || "GeneralEnabledMacro";
    },
    styles() {
      return getDefaultStyles(this.name, true);
    },
    macroIntellisense() {
      if (this.applicationSettings.macroAssist) {
        return [
          {
            displayExpr: "displayName",
            searchExpr: "displayName",
            valueExpr: "displayName",
            dataSource: macroStore(),
            marker: ".",
            minSearchLength: 2
          },
          {
            displayExpr: "displayName",
            searchExpr: "displayName",
            valueExpr: "displayName",
            dataSource: macroStore(),
            marker: "\\",
            minSearchLength: 2
          },
          {
            dataSource: ["C", "D", "M", "S"],
            marker: ";",
            minSearchLength: 0,
            isExtension: true
          }
        ];
      }
      return [];
    }
  }
};
</script>
<style lang="scss" scoped>
.toolbar {
  width: 100%;
}
.title {
  text-transform: capitalize;
  font-size: 1.2rem;
}
.is-invalid {
  border-color: #dc3545 !important;
}
.is-valid {
  border-color: #28a745 !important;
}

::v-deep .dx-icon,
::v-deep .dx-dropdowneditor-icon {
  &::before {
    color: #333 !important;
  }
}
</style>
