<template>
  <form
    @submit.prevent="submitTask"
    :key="isLoading"
    class="p-1"
    v-shortkey="saveShortkey"
    @shortkey="submitTask"
  >
    <fieldset :disabled="isLoading">
      <div class="d-flex align-items-center">
        <h2 v-if="!isClientUser">{{ taskId ? "Edit" : "Create" }} Task</h2>
        <h2 v-else>Send message</h2>
        <loader size="small" v-show="isLoading" />
      </div>
      <div class="d-flex flex-wrap">
        <select-input
          v-focus
          :items="priorityStore"
          name="priority"
          id="priority"
          label="Priority"
          class="col"
          v-model="task.priority"
          :validator="$v.task.priority"
          :validatorMsgMap="validatorMsgMap"
        />
        <date-picker name="dueDate" class="col" label="Due Date" v-model="task.dueDate" />
      </div>
      <div class="d-flex">
        <select-input
          :validator="$v.task.caseId"
          :disabled="isEditing || !!caseId"
          label="Case"
          class="col"
          name="case"
          v-model="task.caseId"
          placeholder="Select a case."
          :displayExpr="caseNumberAndPatient"
          valueExpr="caseId"
          :dataSource="caseSearch"
          :searchExpr="caseSearchExpr"
        />
        <select-input
          v-if="isClientUser"
          label="Recipient"
          class="col"
          name="case"
          v-model="task.assignedToUserId"
          placeholder="Select a recipient"
          valueExpr="value"
          displayExpr="displayText"
          :items="taskAssginees"
          :validator="$v.task.assignedToUserId"
          :validatorMsgMap="validatorMsgMap"
        />
        <user
          v-else
          label="User"
          class="col"
          v-model="task.assignedToUserId"
          value-expr="id"
          :validator="$v.task.assignedToUserId"
          :validatorMsgMap="validatorMsgMap"
          :required="TaskAssigneeRequired"
        />
      </div>
      <div v-if="task.id" class="d-flex flex-column text-muted align-items-end">
        <span>Created By: {{ task.createdBy }}</span>
        <span>Created On: {{ formatDate(task.createdOn) }}</span>
      </div>

      <text-area-input
        name="description"
        v-model="$v.task.description.$model"
        label="Description"
        maxLength="2001"
        id="description"
        rows="3"
        cols="10"
        class="col"
        :resize="false"
        :validator="$v.task.description"
        :validatorMsgMap="validatorMsgMap"
      />

      <div class="d-flex justify-content-end">
        <button @click="clearTask" type="button" class="mt-3 btn btn-danger mx-2">Cancel</button>
        <button :disabled="$v.$invalid || isLoading" type="submit" class="mt-3 btn btn-primary">
          Save
        </button>
      </div>
    </fieldset>
  </form>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SelectInput from "@/components/common/SelectInput.vue";
import { required, maxLength } from "vuelidate/lib/validators";
import { altKey, filterAccessionNumber, validatorMsgMapBase } from "@/modules/helpers";
import { CasesApi, DropdownApi, TaskApi, UsersApi } from "../../services";
import User from "./Selectors/User.vue";
import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import DatePicker from "../common/DatePicker.vue";
import TextAreaInput from "../TextAreaInput.vue";
import DataSource from "devextreme/data/data_source";
import Loader from "../common/Loader.vue";
import handleErrors from "@/mixins/handleErrors";
import { helpers } from "vuelidate/lib/validators";
export default {
  name: "TaskForm",
  components: {
    SelectInput,
    User,
    DatePicker,
    TextAreaInput,
    Loader
  },
  props: ["isEditing", "taskId", "caseId"],

  validations() {
    return {
      task: {
        description: {
          required,
          maxLength: maxLength(2000)
        },
        priority: {
          required
        },
        dueDate: {
          required
        },
        assignedToUserId: {
          required: value => (this.TaskAssigneeRequired ? helpers.req(value) : true)
        }
      }
    };
  },
  data() {
    return {
      defaultTask: {
        priority: null,
        description: "",
        dueDate: new Date(),
        caseId: null,
        isCompleted: false,
        isDeleted: false,
        assignedToUserId: null
      },
      isLoading: false,
      taskAssginees: [],
      caseSearchExpr: ["accessionNumber", "patientName", "patientDOB"],
      priorityStore: [],
      gridBoxValue: [],
      selectedCase: null,
      loading: false,
      task: {
        priority: null,
        description: "",
        dueDate: new Date(),
        caseId: null,
        isCompleted: false,
        isDeleted: false,
        assignedToUserId: null
      },
      caseSearch: new DataSource({ store: CasesApi.searchStore, key: "caseId" }),

      dropdownColumns: [
        {
          dataField: "accessionNumber",
          calculateFilterExpression: filterAccessionNumber(
            "accessionNumber",
            this.$store.state.labSettings.AccessionNumberingType
          )
        },
        {
          dataField: "patientName"
        },
        {
          dataField: "patientDOB",
          dataType: "date"
        }
      ],
      saveShortkey: altKey("s")
    };
  },
  mixins: [handleErrors],
  created() {
    DropdownApi.getTaskPriorities(this.currentLab).then(res => {
      this.priorityStore = res || [];
    });
    if (this.taskId) {
      this.isLoading = true;
      TaskApi.getTaskById(this.taskId).then(res => {
        this.task = res;
        this.isLoading = false;
      });
    }
    if (this.isClientUser) {
      UsersApi.getTaskAssignees().then(res => {
        this.taskAssginees = res;
      });
    }
  },
  mounted() {
    if (this.caseId) {
      this.task.caseId = Number(this.caseId);
    }
  },
  computed: {
    ...mapState({ TaskAssigneeRequired: state => state.labSettings.TaskAssigneeRequired }),
    ...mapState(["currentUser", "currentLab"]),
    ...mapGetters(["permissions", "isClientUser"]),

    validatorMsgMap() {
      return { ...validatorMsgMapBase };
    }
  },
  methods: {
    caseNumberAndPatient(item) {
      return item && `${item.accessionNumber} -- ${item.patientName} `;
    },
    formatDate(data) {
      const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const date = utcToZonedTime(data + "Z", currentTz);
      return format(date, "MM/dd/yyyy hh:mm aa");
    },
    updateCaseId(selected) {
      this.selectedCase = selected;
      return (this.task.caseId = selected.caseId);
    },
    clearTask() {
      this.task = { ...this.defaultTask };
      this.selectedCase = null;
      this.$emit("cancel");
    },
    async submitTask() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        window.notify("Please verify your input and try again.", "warning");
        return;
      }
      try {
        this.isLoading = true;
        if (this.task.id) {
          await TaskApi.updateTask({ ...this.task });
          window.notify(`Success, updating task.`);
        } else {
          await TaskApi.insertTask({ ...this.task, labId: this.currentLab });
          window.notify(`Success, creating task.`);
        }
        this.$emit("submit", this.task);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },

    setCase(data) {
      if (data) {
        this.selectedCase = data;
        this.task.caseId = data.caseId;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
